import React, {useState} from 'react'
import {deleteLiveReconRequest, getLiveReconRequest, getSettingsRequest, postLiveReconRequest, postSettingsRequest, putLiveReconRequest} from './requests'
import {toast} from "react-toastify";
import { FontSize } from 'components/atoms/Generic/index';

export default function LiveReconClient(props) {
  const [exchangeDynamicSettings, setExchangeDynamicSettings] = useState(null)
  const [exchangeReadOnlyFromLiveRecon, setExchangeReadOnlyUsingLiveRecon] = useState(null)
  const [exchangeGlobalPostOnlyFromLiveRecon, setExchangeGlobalPostOnlyUsingLiveRecon] = useState(null)
  const [timersFromLiveRecon, setTimersFromLiveRecon] = useState(null)

  return (
    <div>
      <h3>Live Recon Client</h3>
      <b>Exchange Configured Read only Configs Values</b>
      <div>
        <button onClick={() => getLiveReconRequestAndSet('%2Fmode%2Freadonly', setExchangeReadOnlyUsingLiveRecon)}>
          Fetch
        </button>
        <div>Configs: {JSON.stringify(exchangeReadOnlyFromLiveRecon)}</div>
      </div>
      <br/>
      <b>Exchange Configured Read only status</b>
      <div>
        <button onClick={() => toggleExchangeReadOnlyUsingLiveRecon(true)}>
          Enable Exchange ReadOnly
        </button>
        <button onClick={() => toggleExchangeReadOnlyUsingLiveReconWithConfirmationIfNotInPostOnlyMode(false)}>
          Disable Exchange ReadOnly
        </button>
      </div>
      <br/>
      <b>Exchange Configured GoIntoReadOnlyModeOnResume</b>
      <div>
        <button onClick={() => toggleExchangeGoIntoReadOnlyModeOnResumeUsingLiveRecon(true)}>
          Enable Exchange GoIntoReadOnlyModeOnResume
        </button>
        <button onClick={() => toggleExchangeGoIntoReadOnlyModeOnResumeUsingLiveRecon(false)}>
          Disable Exchange GoIntoReadOnlyModeOnResume
        </button>
      </div>
      <br/>

      <div>---</div>
      <b>Exchange: Global Post-Only Mode</b>
      <div>
        <button onClick={() => getLiveReconRequestAndSet('%2Fmode%2Fglobal-post-only', setExchangeGlobalPostOnlyUsingLiveRecon)}>
          Fetch
        </button>
        <div>Current: {JSON.stringify(exchangeGlobalPostOnlyFromLiveRecon)}</div>
      </div>
      <div>
        <button onClick={() => toggleExchangeGlobalPostOnlyModeUsingLiveRecon(true)}>
          Enable Exchange Global Post-only Mode
        </button>
        <button onClick={() => toggleExchangeGlobalPostOnlyModeUsingLiveRecon(false)}>
          Disable Exchange Global Post-only Mode
        </button>
      </div>
      <br/>
      <div>---</div>
      <b>Exchange Dynamic Settings</b>
      <div>
        <button onClick={() => getLiveReconRequestAndSet('%2Fexchange%2Fexchange-settings', setExchangeDynamicSettings)}>
          Fetch
        </button>

        <div>{exchangeDynamicSettings && Object.keys(exchangeDynamicSettings).map(key => {
          const item = exchangeDynamicSettings[key];
          return (<div style={{fontSize: "0.7em"}}> {key}:{item.currentValue} {item.configType === "BOOLEAN" ?
            <button onClick={() => toggleExchangeDynamicSetting(item.configType, item.configId, key, item.currentValue === "false" ? "true" : "false")}>toggle to {item.currentValue === "false" ? "true" : "false"}</button> : <div>N/A</div>}  </div>)
        })}</div>
      </div>
      <br/>
      <div>---</div>
      <b>Timers</b><br/>
      <button onClick={() => getLiveReconRequestAndSet('%2Fexchange%2Ftimers', setTimersFromLiveRecon)}>Get Timers</button>
      <div>Current: {JSON.stringify(timersFromLiveRecon)}</div>
      <br/>
      <br/>
      <button onClick={() => toggleTimerUsingLiveRecon("MDC Service Buckets", 3, true)}>Turn Market Data Buckets Timer On</button>
      <br/>
      <br/>
      <button onClick={() => toggleTimerUsingLiveRecon("MDC Service Buckets", 3, false)}>Turn Market Data Buckets Timer Off</button>

      <br/><br/><b>Checksum</b><br/>
      <button onClick={() => postLiveReconRequest('%2Frequest-checksums-from-exchange', {})}>Request Full checksums from exchange</button>
      <button onClick={() => postLiveReconRequest('%2Fminimal-request-checksums-from-exchange', {})}>Request Minimal checksums from exchange</button>
      <br/><br/><b>Minimal Checksum data Reset </b><br/>
      <button onClick={() => putLiveReconRequest('%2Fminimal-checksum-data-reset%2F0', {})}>Reset Minimal Checksum Account Balances</button>
      <br/><br/><b>State and Snapshots</b><br/>
      <button onClick={() => postLiveReconRequest('%2Fbroadcast-local-state-to-handover-clients', {})}>Broadcast local state to handover clients</button>
      <button onClick={() => {
        const channel = window.prompt('Please provide channel:');
        postLiveReconRequest('%2Freplay-latest-broadcast-snapshot', {channel})
      }}>Replay latest broadcast snapshot
      </button>
      <button onClick={() => {
        const channel = window.prompt('Please provide channel:');
        const id = window.prompt('please provide id');
        postLiveReconRequest('%2Freplay-persisted-snapshot-from-db', {channel, id})
      }}>Replay persisted snapshot from db
      </button>
    </div>
  )
}

const getLiveReconRequestAndSet = async (url, set) => {
  set(null)
  const result = await getLiveReconRequest(url)
  set(result)
}

const toggleExchangeReadOnlyUsingLiveRecon = async enabled => {
  const result = await putLiveReconRequest('%2Fmode%2Freadonly', {value: enabled})
  console.log(result)
  if (!!result && !result.error) toast.success('Success. Please fetch again')
}

const toggleExchangeGoIntoReadOnlyModeOnResumeUsingLiveRecon = async enabled => {
  const result = await putLiveReconRequest('%2Fmode%2Freadonly-on-resume', {value: enabled})
  if (!!result && !result.error) toast.success('Success. Please fetch again')
}

const toggleExchangeDynamicSetting = async (configType, configId, configValue, value ) => {
  const body = { configType, configId, value }

  if (!window.confirm(`are you sure you want to toggle ${configValue} to ${value}`)) {
    alert('request not sent')
    return
  }

  const result = await putLiveReconRequest('%2Fexchange%2Fexchange-settings', body )
  if (!!result && !result.error) {
    toast.success('Success. Please fetch again')
    setTimeout(fetch)
  }
}

const toggleExchangeGlobalPostOnlyModeUsingLiveRecon = async enabled => {
  const result = await putLiveReconRequest('%2Fmode%2Fglobal-post-only', {value: enabled})
  if (!!result && !result.error) toast.success('Success. Please fetch again')
}

const toggleTimerUsingLiveRecon = async (friendlyName, timerTypeId, value) => {
  let op = ''
  if (value === true) {
    op = "ON"
  } else {
    op = "OFF"
  }
  const confirm = window.confirm(`Please confirm you want to toggle timer ${friendlyName} ${op}`);
  if (!confirm) {
    return
  }

  const result = await putLiveReconRequest('%2Fexchange%2Ftoggle-timer', {timerTypeId: timerTypeId, enabled: value})
  if (!!result && !result.error) toast.success('Success. Please fetch again')
}

const toggleExchangeReadOnlyUsingLiveReconWithConfirmationIfNotInPostOnlyMode = async enabled => {
  const exchangeGlobalPostOnlyModeResult = await getLiveReconRequest('%2Fmode%2Fglobal-post-only')

  if (exchangeGlobalPostOnlyModeResult.globalPostOnlyMode === false) {
    const userConfirmed = window.confirm('Exchange Global Post-Only mode is currently DISABLED.\n\nDo you want to proceed ?')

    if (!userConfirmed) {
      return
    }
  }
  return toggleExchangeReadOnlyUsingLiveRecon(enabled)
}
